<!--suppress ALL -->
<template>
  <div class="wrapper">
    <div class="main-article">
      <row>
        <column
          md="12"
          sm="12"
          class="article-center-column">
          <row>
            <column
              lg="8"
              md="12"
              class="article-center-column">
              <article-lead-asset class="mb-2 mb-md-3" />
            </column>
            <column
              md="12"
              lg="4"
              class="article-center-column">
              <article-image-credit class="mb-4 mb-lg-0" />
            </column>
          </row>
          <row>
            <column
              md="12"
              lg="12"
              class="article-center-column">
              <template v-if="!leadImageExists">
                <p class="mt-4"></p>
              </template>
              <template v-if="!pageTitle">
                <article-title class="ff-sueca fs-xxl fw-bold mb-4" />
              </template>
              <article-lead-text class="ff-sueca fs-lg mb-4" />
              <article-body class="ff-sueca article-body"/>
            </column>
          </row>
        </column>
      </row>
    </div>
  </div>
</template>
<script>
import { Page, ArticleComponents } from 'global-components';
export default {
  name: 'info-article-page',
  dataResolver: 'infoArticlePage',
  components: {
    ...ArticleComponents
  },
  extends: Page,
  computed: {
    pageTitle() {
      return this.$route.meta.pageContext.pageHead || '';
    },
    leadImageExists() {
      return this.$store.state.article.leadAsset.imageSource || false;
    }
  }
};
</script>
